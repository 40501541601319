<template>
  <div class="is-relative"
    style="height: 100%; width: 100%; object-fit: contain;">
    <div class="loading-image"
      style="height: 100%; width: 100%; object-fit: contain;"
      v-if="!isLoaded" />
    <img :src="src"
      style="height: 100%; width: 100%; object-fit: contain;"
      @load="setLoading(false)">
  </div>
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    isLoaded() {
      return !this.loading && this.src != '' && this.src != null
    }
  },
  watch: {
    src: function (val, oldVal) {
      this.setLoading(true)
    }
  },
  methods: {
    setLoading(state) {
      console.log('x', state)
      this.loading = state
    }
  }
}
</script>
