<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p
        class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span
          v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i
            class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        Copy Images
      </p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <div class="control">
          <label class="label">Quote No.</label>
          <input
            class="input"
            v-model="quoteNo"
            @input="searchQuote"
            placeholder="Start typing to search..."
            v-focus-inserted>
          <span
            v-if="validQuote"
            class="help is-success">Valid Quote No.</span>
          <span
            v-else-if="quoteNo.length > 0"
            class="help is-danger">Invalid Quote No.</span>
        </div>
      </div>
    </section>
    <footer
      class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button
        class="button is-success tooltip"
        @click="copy()"
        data-tooltip="Start copying images"
        :disabled="!validQuote">Start Copying</button>
      <button
        class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import QuoteService from '../QuoteService'
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import { FocusInserted } from '@/components/directives'
import { guidValidator } from '@/components/validators'

export default {
  name: 'QuoteNoSearchModal',
  directives: {
    FocusInserted
  },
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-content-copy'
    }
  },
  data() {
    return {
      isActive: false,
      quoteNo: '',
      searchedQuoteId: ''
    }
  },
  validations: {
    searchedQuoteId: {
      validGuid: guidValidator
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validQuote() {
      return this.$v.searchedQuoteId.$dirty && !this.$v.searchedQuoteId.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    copy() {
      this.$emit('copy', this.searchedQuoteId)
    },
    close() {
      this.$emit('close')
    },
    searchQuote: _debounce(async function() {
      this.$v.searchedQuoteId.$touch()
      if (this.quoteNo) {
        this.searchedQuoteId = await QuoteService.getQuoteIdByNo(this.quoteNo)
        // this.$forceUpdate()
        // console.log(this.searchedQuoteId)
      } else {
        this.searchedQuoteId = Guid.empty()
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
