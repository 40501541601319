<template>
  <div class="button is-info"
    v-if="this.$agentIsScannerReady"
    @click="()=>scan()">
    <i class="mdi mdi-scanner mdi-24px" />
    Scan
  </div>
</template>

<script>
import AgentService from '@/services/AgentService'
import StoreUtil from '@/store/utils'
import StoreMixin from './storeMixin'

export default {
  name: 'AgentScanButton',
  components: {},
  mixins: [StoreMixin],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async scan() {
      if (!this.$agentIsScannerReady) {
        return
      }

      let deviceName = StoreUtil.getLocalStorage('scanner', 'deviceName')
      let resolutionName = StoreUtil.getLocalStorage('scanner', 'resolutionName')
      let pixelTypeName = StoreUtil.getLocalStorage('scanner', 'pixelTypeName')

      let b64Image = await AgentService.Scanner.scan(deviceName, resolutionName, pixelTypeName)
      //await AgentService.Scanner.getDevices(deviceName, resolutionName, pixelTypeName)

      this.$emit('scan-results', b64Image)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>