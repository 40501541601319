<template>
  <div style="height: 100%">
    <vue-dropzone ref="filedropzone"
      :id="'filedropzone'+id"
      :options="dropzoneOptions"
      @vdropzone-file-added="(file)=>acceptFile(file)"
      @vdropzone-processing="(file)=>processFile(file)"
      @vdropzone-sending="(file, xhr)=>sendingFile(file, xhr)"
      @vdropzone-complete="(file) =>successFile(file)"
      :additional-classes="additionalClasses" />
  </div>
</template>
<script>
import VueDropzone from '@/components/VueDropzone'
import QuoteService from '@/views/quote/QuoteService'

export default {
  name: 'FileDropZone',
  components: {
    VueDropzone
  },
  props: {
    quoteId: {
      type: String
    },
    quoteAttachmentCategoryId: {
      type: String
    },
    previewsContainer: {
      type: String,
      default: null
    },
    defaultSize: {
      type: Number,
      default: 128
    },
    additionalClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: null,
      dropzoneOptions: {
        url: '/',
        dictDefaultMessage: 'Drop files here or click to upload',
        autoProcessQueue: false,
        thumbnailWidth: this.defaultSize,
        thumbnailHeight: this.defaultSize,
        maxFilesize: 10,
        resizeWidth: 800,
        acceptedFiles: 'image/*, application/pdf',
        previewsContainer: this.previewsContainer
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.id = this._uid + ''
  },
  methods: {
    async acceptFile(file) {
      this.$showSpinner()
      //console.log('accept', file)
      let packet = (await QuoteService.getQuoteAttachmentUploadUrl(this.quoteId)).data
      file.uploadURL = packet.url
      file.guid = packet.guid
      //console.log('url for file', file)
      this.$refs.filedropzone.processFile(file)
    },
    async processFile(file) {
      this.$refs.filedropzone.changeUrl(file.uploadURL)
    },
    sendingFile(file, xhr) {
      //console.log('sending')
      console.log(file, xhr)
    },
    async successFile(file) {
      //console.log('success', file)
      await QuoteService.setupQuoteAttachment(this.quoteId, this.quoteAttachmentCategoryId, file.guid, file.name)
      this.$emit('image-uploaded', {})
      this.$refs.filedropzone.removeFile(file)
      this.$hideSpinner()
    }
  }
}
</script>