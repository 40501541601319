import FqApi from '@/services/fq-api'

export default {
  async getImage(id) {
    const url = `/quotes/images/data/${id}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // console.log(result)

    // const image = btoa(String.fromCharCode(...new Uint8Array(result.data)))
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  },
  async saveImage(id, formData) {
    const url = `/quotes/images/save/${id}`
    const result = await FqApi.post(url, formData)
    return result
  }
}
