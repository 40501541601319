//import axios from 'axios'
import { jsPDF } from 'jspdf'

const loadImage = src =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })

const scaledDimensions = (wi, hi, ws, hs) => {

  let ri = wi / hi
  let rs = ws / hs

  if(rs > ri) {
    return {width: wi * hs/hi, height: hs}
  } else {
    return {width: ws, height: hi * ws/wi}
  }
}

const convertImagesToPDF = async function(imageDataUrls) {
  var doc = new jsPDF()
  doc.deletePage(1)

  for(var url of imageDataUrls) {
    var image = await loadImage(url)

    //let imageData = (await axios.get(url)).data

    //console.log(imageData)
    doc.addPage()
    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()

    var scaledSize = scaledDimensions(image.width, image.height, width, height)

    await doc.addImage(url, 'JPEG', 0, 0, scaledSize.width, scaledSize.height)
  }

  return await doc.output('datauristring')
}

const downloadUrl = (url, fileName) => {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()
  URL.revokeObjectURL(link.href)
}

export { convertImagesToPDF, downloadUrl }